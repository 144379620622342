import { NgModule } from '@angular/core';

import { ProfilService } from './profil.service';
import { UserHasRechtPipe } from './has-recht.pipe';
import { UserHasRollePipe } from './has-rolle.pipe';
import { UserInfoFacade } from './user-info.facade';

@NgModule({
  declarations: [
    UserHasRechtPipe,
    UserHasRollePipe
  ],
  exports: [
    UserHasRechtPipe,
    UserHasRollePipe
  ],
  imports: [],
  providers: [
    ProfilService,
    UserInfoFacade
  ]
})
export class UserInfoModule { }